import React, { useState, useEffect } from 'react';
import Price from '../../../components/product/common/Price';
import Popup from '../../../components/common/Popup';
import Image from '../../../components/common/Image';
import W from '../../../components/common/Word';
import FarmshopBundleButton from './BundleButton';
import flatstore from 'flatstore';
import { currentLang } from "../../../utils/functions";
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';

const FarmshopBundleBox = ({ bundle, farmshop_data, setCartHandler, initialBundle }) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [cart, setCart] = useState({});
  const [bundleId, setBundleId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submissionNotice, setSubmissionNotice] = useState({ message: '', className: '' });
  const { t } = useTranslation();

  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };

  useEffect(() => {
    if (initialBundle && initialBundle === bundle.title) {
      setPopupVisible(true);
    }
  }, [initialBundle, bundle]);

  useEffect(() => {
    let timeout;
    if (submissionNotice) {
      timeout = setTimeout(() => {
        setSubmissionNotice({ message: '', className: '' });
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [submissionNotice, setSubmissionNotice]);

  useEffect(() => {
    if (popupVisible) {
      const initialCart = {};
      bundle.bundle_items.forEach((item, index) => {
        const product = farmshop_data.find(product => product.relation_slug === item.attribute_name);
        if (product) {
          initialCart[product.bundled_item_id] = {
            product_id: product.meatcut_product_id,
            quantity: item.amount
          };

          if (index === 0) {
            setBundleId(product.bundle_id);
          }
        }
      });
      setCart(initialCart);
    }
  }, [popupVisible, bundle.bundle_items, farmshop_data]);

  const updateCartItem = (bundle_id, newQuantity) => {
    setCart(prevCart => ({
      ...prevCart,
      [bundle_id]: {
        ...prevCart[bundle_id],
        quantity: newQuantity
      }
    }));
  };

  const handleAddToCart = () => {
    const filteredCart = Object.fromEntries(
      Object.entries(cart).filter(([key, value]) => value.quantity > 0)
    );

    // Push data to dataLayer based on filteredCart
    if( Object.keys(filteredCart).length > 0 ){
      window.dataLayer = window.dataLayer || [];
      for (const [bundleId, { quantity }] of Object.entries(filteredCart)) {
        const farmshopItem = farmshop_data.find(item => item.bundled_item_id == bundleId);
        if (farmshopItem) {
          const { relation_slug, title, price } = farmshopItem;

          for (let i = 0; i < quantity; i++) {
            window.dataLayer.push({
              'productInfo': {
                'product_id': relation_slug,
                'title': title,
                'shop_package_price': price,
                'category_slug': 'grutto_custom_organic',
                'shop_animal_brand': 'Farmshop',
                'is_bundle': true
              },
              'event': 'grutto-product-addtocart'
            });
          }
        }
      }
    }

    const farmshopItems = {};
    farmshop_data.forEach(item => {
      let formQuantity = 0;
      if (window[`product_${item.bundled_item_id}`]) {
        formQuantity = window[`product_${item.bundled_item_id}`];
      }

      if (formQuantity) {
        farmshopItems[item.bundled_item_id] = {
          product_id: item.meatcut_product_id,
          quantity: formQuantity
        };
      }
    });

    const mergeAndSumQuantities = (obj1, obj2) => {
      const merged = { ...obj1 };

      for (const [key, value] of Object.entries(obj2)) {
        if (merged[key]) {
          merged[key] = {
            ...merged[key],
            quantity: merged[key].quantity + value.quantity
          };
        } else {
          merged[key] = value;
        }
      }

      return merged;
    };

    const mergedCart = Object.keys(farmshopItems).length > 0 && Object.keys(filteredCart).length > 0
      ? mergeAndSumQuantities(filteredCart, farmshopItems)
      : filteredCart;

    if (!loading && filteredCart) {
      setLoading(true);
      flatstore.set('basket_status', true);

      const formData = new FormData();
      formData.append('product_id', bundleId);
      formData.append('bundle_items', JSON.stringify(mergedCart));

      fetch(`/${currentLang()}/shop/ajaxflow/add-bundle-to-cart`, {
        method: 'POST',
        body: formData
      })
        .then(response => response.json())
        .then(data => {
          if (data.success && data.data.cart_data) {
            setLoading(false);
            flatstore.set('basket_count', data.data.cart_data);
            flatstore.set('basket_status', false);
            var cartItems = [];
            data.data.items.map((item) => {
              if (!isNil(item.bundled_item_id)) {
                window[`product_${item.bundled_item_id}`] = item.quantity;
                cartItems.push({
                  bundle_id: item.bundled_item_id,
                  quantity: item.quantity,
                });
              }
            });
            setCartHandler(cartItems);
            togglePopup();
            setSubmissionNotice({ message: `${bundle.title}${t(`farmshop.notification.add_to_cart`)}`, className: 'success' });
          } else {
            setLoading(false);
            flatstore.set('basket_status', false);
            togglePopup();
            setSubmissionNotice({ message: t(`error`), className: 'error' });
          }
        })
        .catch(error => {
          flatstore.set('basket_status', false);
          setLoading(false);
          togglePopup();
          setSubmissionNotice({ message: t(`error`), className: 'error' });
          console.error('Error adding bundle to cart:', error);
        });
    } else {
      togglePopup();
      setSubmissionNotice({ message: t(`error`), className: 'error' });
    }
  };

  return (
    <>
      {submissionNotice.message && (
        <div className={`response ${submissionNotice.className}`}>
          {submissionNotice.message}
        </div>
      )}

      <div className='bundle-item' onClick={togglePopup}>
        <div className='body'>
          <div className='heading'>
            <div className='title'>{bundle.title}</div>
            <div className='desc'>{bundle.subtitle}</div>
          </div>
          <div className='btn btn--white'><W>farmshop.bundle.popup_button</W></div>
        </div>
        <div className='img-holder'>
          <Image
            type="farmshop.list"
            title={bundle.title}
            src={bundle.image}
            alt={bundle.title}
          />
        </div>
      </div>

      <Popup show={popupVisible} name={bundle.title} handleClose={togglePopup}>
        <div className={`bundles-body ${loading ? "loading" : ""}`}>
          <p className='desc'>{bundle.content}</p>
          <div className='bundles-list'>
            {bundle.bundle_items.map((item, index) => {
              const product = farmshop_data.find(product => product.relation_slug === item.attribute_name);

              return product ? (
                <div key={index} className='bundles__popup-item'>
                  <div className='img'>
                    <Image
                      type="farmshop.list"
                      title={product.title}
                      src={product.image_url}
                      alt={product.title}
                    />
                  </div>
                  <div className='item-info'>
                    <div className='body'>
                      <h4 className='name'>{product.title}</h4>
                      <div className="farmer">
                        <div className="farmer_image">
                          <Image
                            type="farmers.card"
                            className="img-fluid"
                            alt={product.farmer_title}
                            title={product.farmer_title}
                            src={product.farmer_image_url}
                          />
                        </div>
                        <div className="farmer_name">
                          <W>farmers.{product.animal_type}</W>: {product.farmer_title}
                        </div>
                      </div>
                    </div>
                    <div className='action'>
                      <div className="farmshop__item__info">
                        {product.price != product.regular_price && (
                          <>
                            <div className="sale_price p-prev">
                              <Price value={product.price} withCurrency={false} />
                            </div>
                            <div className="sale_price price">
                              <Price value={product.regular_price} withCurrency={false} />
                            </div>
                          </>
                        )}
                        {product.price == product.regular_price && (
                          <>
                            <div className="price">
                              <Price value={product.price} />
                            </div>
                          </>
                        )}
                        <span className="sep"> - </span>
                        <span className="weight">
                          {product.pieces_per_vac} x {product.weight} <W>farmshop.unit_txt.{product.unit_type ? product.unit_type : 'g'}</W>
                        </span>
                      </div>
                      <FarmshopBundleButton
                        key={index}
                        bundle_id={product.bundled_item_id}
                        quantity={item.amount}
                        onUpdateQuantity={updateCartItem}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                null
              );
            })}
          </div>
        </div>
        {loading &&
          <div className='bundles-loading'></div>
        }
        <div className='bundles-footer'>
          {loading ?
            <button className="btn btn--disable">
              <i className="icon-plus"></i>
              <span><W>farmshop.bundle.add_to_cart_button</W></span>
            </button>
            :
            <a className="btn" onClick={handleAddToCart}>
              <i className="icon-plus"></i>
              <span><W>farmshop.bundle.add_to_cart_button</W></span>
            </a>
          }
        </div>
      </Popup>
    </>
  );
};

export default FarmshopBundleBox;
