import React, { Fragment } from 'react'
import PackagePrice from '../../product/packages/PackagePrice';
import ProfileFarmer from '../../product/profile/ProfileFarmers';

import ProfileBuyer from '../../product/profile/ProfileBuyer';
import ProfilePackagesRemaining from '../../product/profile/ProfilePackagesRemaining';

import AnimalFill from '../../product/common/AnimalFill';
import AnimalTag from '../../product/common/AnimalTag';
import ProductSlider from '../../product/common/ProductSlider';
import { Link } from 'react-router-dom';
import {currentLang, productURL} from '../../../utils/functions';
import {isNil} from 'lodash';
import { useTranslation } from 'react-i18next';
import W from '../../common/Word';

const ProductCard = ({data}) => {
    const { t } = useTranslation();

    return (
        <div className={"package__item package--" + data.shop_brand_slug + (data.is_organic == 1 || data.is_demeter == 1 ? " package--bio" : "") + (data.is_special == 1 ? " package--special" : "") + (data.is_highlight && " highlight--"+data.is_highlight) + (data.is_msc == 1 ? " package--msc" : "") + (data.has_label_rouge == 1 ? " package--label-rouge" : "") + (" " + data.category_slug) + (" " + currentLang())}>

            <div className="package__title">
              <Link to={productURL(t('menu.products'), data.slug, data.shop_brand_slug)}>
                <span className="heading">
                  {(data.is_organic == 1 || data.is_demeter == 1) &&
                    <span className="text--green bio"> <W>general.organic</W> </span>
                  }
                  {data.title}
                  {(data.is_special == 1) &&
                    <span className="text--red special"> <W>product.special</W> </span>
                  }
                  {(data.is_highlight == "express") &&
                    <span className="text--orange special"> <W>product.express</W> </span>
                  }
                </span>
              </Link>
            </div>
            <div className="package__slider">
              {data.main_slider && (
                <ProductSlider
                  type="products.card"
                  data={data.main_slider}
                  percentageBoxData={{
                    shop_product_percentage_sold: data.shop_product_percentage_sold,
                    shop_brand_slug: data.shop_brand_slug,
                    shop_category_slug: data.shop_category_slug,
                    shop_animal_nr_prefix: data.shop_animal_nr_prefix,
                    shop_animal_nr: data.shop_animal_nr,
                    category_slug: data.category_slug,
                    slug: data.slug,
                  }}
                  url={productURL(t('menu.products'), data.slug, data.shop_brand_slug)}
                />
              )}
              <Link
                to={productURL(t('menu.products'), data.slug, data.shop_brand_slug)}
                className="link"
              ></Link>
            </div>
            <div className='package__item__farmer'>
              <ProfileFarmer
                  name={data.farmer_name}
                  title={data.farmer_title}
                  body={data.farmer_summary}
                  location={data.farmer_location}
                  image={data.farmer_image_url}
                  breeds={data.category_slug != 'grutto_farmer_package' && data.shop_animal_name}
                  link={data.farmer_slug}
                  earnumber={data.category_slug != 'grutto_farmer_package' && data.shop_animal_nr}
                  isnew={data.farmer_is_new}
                  animal={data.shop_brand_slug}
              />
            </div>
            <div className="package__info">
                <ul className="list list--no-list">
                  {isNil(data.meatcut_pieces_suffix) &&
                    <li>{data.shop_package_product_count} <W>product.differentmeatcuts</W></li>
                  }
                  {!isNil(data.meatcut_pieces_suffix) &&
                    <li>{data.shop_package_product_count} {data.meatcut_pieces_suffix}</li>
                  }
                  <li>{parseInt(data.shop_package_total_meals_for_person)} <W>product.meals</W>  /  {data.shop_package_frontend_size}</li>
                  <li>{data.additional_info}</li>
                </ul>
            </div>
            <div className='short-text d-none'>
                {data.card_description &&
                  data.card_description
                }
            </div>
            <div className="product-info">
                <PackagePrice
                  fullPrice={data.shop_package_price && data.shop_package_price.toFixed(2)}
                  mealPrice={data.shop_package_price_per_meals && data.shop_package_price_per_meals.toFixed(2)}
                  prevPrice={data.previous_price}
                />
                <ProfileBuyer
                    totalBuyers={data.shop_product_buyer_count}
                    totalPackages={data.shop_product_total_package_available}
                    soldPercentage={data.shop_product_percentage_sold}
                    animalType={data.shop_brand_slug}
                />
                {/* <ProfilePackagesRemaining
                    totalBuyers={data.shop_product_buyer_count}
                    totalPackages={data.shop_product_total_package_available}
                    soldPercentage={data.shop_product_percentage_sold}
                    animalType={data.shop_brand_slug}
                /> */}
                {data.meals_range_per_package &&
                  <div className='meals-per-package d-none'>
                    <span className="icon icon-dish"></span>
                    <span className="dish__info">
                      <strong>{data.meals_range_per_package}</strong>
                      <span><W>basic_recipe.person</W></span>
                    </span>
                  </div>
                }
            </div>

            <div className="package__action align-self-center">

                {data.shop_product_stock_status != "instock" &&
                  <Link to={productURL(t('menu.products'), data.slug, data.shop_brand_slug)} className="btn btn--dark btn--full"><W>shop.status.{data.shop_product_stock_status}</W></Link>
                }
                {data.shop_product_stock_status == "instock" &&
                  <Link to={productURL(t('menu.products'), data.slug, data.shop_brand_slug)} className="btn btn--secondary btn--full">
                    <W>shop.status.{data.shop_product_stock_status}_view</W>
                </Link>
                }
            </div>
        </div>
    )
}

export default ProductCard
