import React, {useEffect, useState, Fragment} from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import flatstore from 'flatstore';
import {getMenu} from '../../../utils/services';
import {currentLang, productURL} from '../../../utils/functions';
import Basket from '../../../components/product/common/Basket';
import { useTranslation } from 'react-i18next';
import W from '../../../components/common/Word';
import {isNil, last} from 'lodash';
import parse from 'html-react-parser';

const Navbar = ({ menu_data_test, second_menu_data_test }) => {
  const [items, setItems] = useState(null);
  const [secondMenuItems, setSecondMenuItems] = useState(null);

  const [toggled, setToggled] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null); // Track the active menu item ID
  const { t } = useTranslation();
  const {pathname} = useLocation();

  const slug = last(window.location.href.replace(/\/$/, "").split('/'));
  const mobile = (window.innerWidth >= 992) ? false : true;
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [subMenuMobile, setSubMenuMobile] = useState(false);
  const submenuToggle = (value) =>{
    setSubMenuOpen(value);
  }

  useEffect(() => {
    setToggled(false);
  }, [pathname])

  function HandleHamburgerMenu(event){
    event.preventDefault();
    setToggled(!toggled);
  }

  const closeMenu = e => {
    setToggled(!toggled);
  }

  let toggledClass = (toggled) ? "collapsed menu__open" : "collapse menu__close";
  document.body.className = toggledClass;


  function list_to_tree(list) {
    var map = {}, node, roots = [], i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].menu_item_id] = i;
      list[i].items = [];
    }
    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (!isNil(node.parent_id)) {
        list[map[node.parent_id]] && list[map[node.parent_id]].items.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }
  useEffect(() => {

    if(isNil(menu_data_test)){
      getMenu('main-menu-test', 'menu_data_test');
    }

		if(!isNil(menu_data_test)){
        if(menu_data_test.length && menu_data_test.length > 0){
          setItems(list_to_tree(menu_data_test));
        }
		}

  }, [menu_data_test]);

  useEffect(() => {
    if (isNil(second_menu_data_test)) {
      getMenu('second-menu', 'second_menu_data_test');
    }
  
    if (!isNil(second_menu_data_test) && second_menu_data_test.length > 0) {
      setSecondMenuItems(list_to_tree(second_menu_data_test));
    }
  }, [second_menu_data_test]);

  var isExternalURL = (url) => {
    if(url.includes('https') || url.includes('/'+currentLang()+'/') || url.includes('//')){
      return true;
    }else{
      return false;
    }
  }

  const toggleSubMenu = (menuItemId) => {
    setActiveMenu(activeMenu === menuItemId ? null : menuItemId); // Toggle active menu ID
  };

  return (
    <div className="container ab_test_menu">
      <div className="header__menu">

          <div className='menu__hamburger_holder'>
            <div className="menu__hamburger" onClick={HandleHamburgerMenu}>
              <button className={`menu__hamburger ${toggledClass}`} type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
                <span className={"icon-bar top-bar"}></span>
                <span className={"icon-bar middle-bar"}></span>
                <span className={"icon-bar bottom-bar"}></span>
              </button>
              <span className={"menu__hamburger__label"}><W>general.menu</W></span>
            </div>
          </div>

          <div className={"header__logo"}>
            <Link className="logo" to={"/"+currentLang()} title={t("website_name")}>  </Link>
          </div>

          <div className="header__account">
            <a href={"/"+currentLang()+"/shop/my-account/"} className="version-a-checkout" title={t("general.account")}> <i className={"icon-user"}></i> <span className="text"><W>general.account</W></span> </a>
            <Basket />
          </div>
          
          <div className="menu__navigation_items">
            <nav><ul>
              {items && items.map((item, index) =>{
                  return (
                    <li 
                      key={'navlink-li-'+index} 
                      // className={"top-menu" + index + (item.items.length > 0 ? " haschild" : "")+((activeMenu === item.menu_item_id) ? " active" : "")+(((activeMenu == item.menu_item_id && subMenuOpen == item.menu_item_id && !mobile) || (mobile && subMenuMobile)) ? ' submenu__open' : '')+((item.icon_class.includes('abtest')) ? ' abtest-none' : '')} onMouseEnter={()=>{setActiveMenu(item.menu_item_id)}} onMouseLeave={()=>{setActiveMenu(false)}}>
                      className={`top-menu${index} ${item.items.length > 0 ? " haschild" : ""} ${
                        activeMenu === item.menu_item_id ? " submenu__open" : ""
                      }`}>

                      {/* <span className="trigger" onClick={()=>{subMenuMobile ? setSubMenuMobile(false) : setSubMenuMobile(true)}}></span> */}
                      <span className="trigger" onClick={() => toggleSubMenu(item.menu_item_id)}></span>

                      <Fragment>
                      {isExternalURL(item.url) &&
                        <a key={"navlink-"+index} href={item.url} className={"toplink"} title={item.title}><span className={item.icon_class}></span>{item.title}</a>
                      }
                      {!isExternalURL(item.url) &&
                      <NavLink key={"navlink-num-"+index} to={"/"+currentLang()+(item.url ? "/"+item.url : "")} className={"toplink"} title={item.title} onClick={() =>{ setToggled(false); if(mobile && item.items.length == 0 && subMenuMobile){ setSubMenuMobile(false)}}} >
                              <span className={item.icon_class && item.icon_class}></span>
                              <span className='txt'>{item.title}</span>
                      </NavLink>
                      }

                      {item.items.length > 0 &&
                      <Fragment>
                      <ul key={'ul-menu-num-'+index} className={"submenu "}>
                        <li key={'li-menu-num-'+index} className={"submenu__body "+currentLang()}>
                          {item.items.map((column, column_i)=>{
                              return (
                                <div key={"menu-column-num-"+column_i} className={"menu-column"}>
                                  {column.items && column.items.map((cat, index)=>{
                                      var catTitle = parse(cat.title);
                                      var slug_r = last(window.location.href.replace(/\/$/, "").split('/'));
                                        return (
                                          <ul key={"animal-ul-"+index} className={"cat-"+index}>
                                            <li key={"animal-li-"+index} className="submenu__title">
                                              {cat.url ? <Link to={"/"+currentLang()+"/"+cat.url } onClick={()=>{setActiveMenu(false); setToggled(false)}} title={cat.title.replace(/<[^>]+>/g, '')} className={cat.url.includes(slug_r) ? 'color--red g-link' : 'g-link '}><span className={cat.icon_class}></span><span className='text'>{catTitle}</span></Link> : <div className='g-title'><span className={cat.icon_class}></span><span className='text'>{catTitle}</span></div> }
                                            </li>

                                            {cat.items && cat.items.length > 0 && cat.items.map((submenu, index)=>{
                                              if(submenu.parent_id == cat.menu_item_id){
                                                var menu_url = "";
                                                if(submenu.url){
                                                  menu_url = submenu.url;
                                                }else if(submenu.product_slug){
                                                  menu_url = productURL(null, submenu.product_slug, submenu.animal_type);
                                                }else{
                                                  menu_url = null;
                                                }
                                                var submenuTitle = parse(submenu.title);
                                                return (
                                                    <li key={"animal-childs-"+index}>
                                                      {isNil(menu_url) &&
                                                          <a>{submenuTitle}</a>
                                                      }

                                                      {!isNil(menu_url) && !menu_url.includes('http') &&
                                                          <Link key={"animal-childs-link"+index} to={menu_url} title={submenuTitle} onClick={()=>{if(!mobile) setActiveMenu(false); setToggled(false)}} className={(slug == submenu.product_slug) ? 'color--red' : ''}>{submenuTitle}</Link>
                                                      }
                                                      {!isNil(menu_url) && menu_url.includes('http') &&
                                                          <a key={"animal-childs-link"+index} href={menu_url} title={submenuTitle}>{submenuTitle}</a>
                                                      }
                                                    </li>
                                                )
                                              }
                                            })}
                                          </ul>
                                        )
                              })}
                              </div>
                              )
                          })}
                        </li>
                      </ul>
                      <div className="submenu__gray-bg" onClick={()=>closeMenu()}></div>
                      </Fragment>
                      }
                      </Fragment>
                    </li>
                    )
                })
              }</ul>


              <ul className='second-menu'>
                {secondMenuItems && secondMenuItems.map((item, index) => (
                  <li key={`second-menu-${index}`}>
                    <a href={item.url}>{item.title}</a>
                  </li>
                ))}
              </ul>



            </nav>
            <div className="submenu__gray-bg--mobile" onClick={()=>closeMenu()}></div>
          </div>
        
      </div>
    </div>
  )
}

export default flatstore.connect(['menu_data_test', 'second_menu_data_test'])(Navbar);