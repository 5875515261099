import React, {Fragment} from 'react'
import FarmshopList from './FarmshopList'
import FarmshopDetail from './FarmshopDetail'
import FarmshopFeedback from './FarmshopFeedback'
import { isNil } from 'lodash';
import { useParams } from 'react-router-dom';


const Farmshop = () => {
	let {slug} = useParams();
	if(isNil(slug)){
		return (<FarmshopList />)
	}else if(slug=='feedback'){
		return (<FarmshopFeedback />)
	}else{
		return (<FarmshopDetail slug={slug} />)
	}
}
export default Farmshop
