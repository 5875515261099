import React, {useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {includes} from 'lodash';
import Navbar2 from '../Navbar2';
import { currentLang } from '../../../utils/functions';
import smoothscroll from 'smoothscroll-polyfill';
import {siteInfo} from './../../../utils/config';

const Header = () => {
  const { pathname } = useLocation();
  const {t} = useTranslation();

  smoothscroll.polyfill();

  useEffect(() => {
    document.documentElement.setAttribute('lang', siteInfo[currentLang()]['lang']);
    if(!includes(pathname, t("menu.animal")) && !includes(pathname, t("menu.faq")) && !includes(pathname, t("menu.products")) && !includes(pathname, t("menu.recipes")) && !includes(pathname, t("menu.farmers"))){
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <header className={"header__top" + (" " + currentLang())}>
        <Navbar2 />
    </header>
  )
}

export default Header